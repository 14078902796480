@import "../../../styles/variables.less";

.contact-form {
    //margin: 50px 50px;
    margin: -25px 50px 50px;

    @media only screen and (max-width: 800px) {
        margin: 20px auto;
    }

    >hr {
        border: 1px solid #f0f0f0;
        margin-bottom: 50px;
    }

    .ant-form {
        margin: 0 auto;
        max-width: 50%;

        @media screen and (max-width: 768px) {
            max-width: 80%;
        }

        .ant-col-hide {
            display: none;
        }

    }

    .ant-btn {
        height: 50px !important;
        background-color: #000 !important;
        border: #000 !important;
    }

    h2 {
        font-size: 3em;
        line-height: 1.2em;
        font-family: @headingFont;
        font-weight: 700;
        color: @banner-heading-color;
        text-align: center;
    }
}