@import "../../styles/variables.less";

.ant-layout-header {
    padding: 0 calc((100% - @header-desktop-width) / 2) !important;
    position: fixed;
    display: flex;
    flex-direction: row;
    z-index: 1000;
    top: 0;
    line-height: @header-size;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    transition: box-shadow .3s ease-in-out;



    &.page-on-top {
        box-shadow: unset;

        &::before {
            height: 0;
        }
    }

    @media only screen and (min-width: @header-desktop-width) {
        padding: 0 calc((100% - 1140px)/2);
    }

    @media only screen and (max-width: @header-mobile-width) {
        padding: 0 0;
        height: @header-size-mobile;
        line-height: @header-size-mobile;
    }

    height: @header-size !important;
    line-height: @header-size !important;
    background-color: @header-background;

    .logo {
        display: block;
        width: @logo-width;
        height: auto;
        padding: 0 10px;

        a {
            img {
                padding: 15px 0 20px;
                width: auto;
                max-height: 100%;
            }
        }

        @media only screen and (max-width: @header-mobile-width) {
            width: 55%;
            max-width: 400px;

            a {
                text-align: left;

                img {
                    //width: 90%;
                }
            }
        }
    }


    .menu {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 90rem;
        display: flex;
        width: 100%;

        @media only screen and (max-width: @header-mobile-width) {
            display: none;
        }

        ul {
            width: 100%;
            background-color: transparent;
            border-bottom: none;
            margin-left: auto;
            border-right: none;
            justify-content: flex-end;

            li {
                border-bottom: none !important;

                &::after {
                    display: none;
                }

                @media screen and (max-width: @header-mobile-width) {
                    display: block;
                }

                a {
                    color: #000;
                    font-size: 17px;
                }

                &.ant-menu-submenu-horizontal::after {
                    content: '>';
                    color: rgba(0, 0, 0, 0.65);
                    transform: rotate(90deg);
                    position: absolute;
                    right: -20px;
                    top: 2px;
                    font-weight: 600;
                }
            }
        }

        &-languages {
            margin-left: 0 !important;
            max-width: 100%;
            padding: 0 10px;

            @media screen and (max-width: @header-mobile-width) {
                margin-right: 70px !important;
                margin-left: auto !important;
            }

            > .ant-menu-submenu-horizontal {
                .ant-menu-title-content {
                    margin-left: 10px;
                }
            }
        }
    }

    .menu-burger-button {
        position: absolute;
        width: auto;
        height: auto;
        top: 18px;
        right: calc(@header-size / 4);

        @media only screen and (min-width: @header-mobile-width) {
            display: none;
        }

        .burger {
            height: calc(@header-size-mobile / 2);
        }
    }

    .menu-burger {
        @media only screen and (min-width: @header-mobile-width) {
            display: none;
        }
    }
}

.ant-menu-sub,
.ant-menu-submenu-popup {
    border-radius: 2px !important;
}

.header-burger-open {
    .menu {
        display: block;
        position: fixed;
        width: 100%;
        height: calc(100% - @header-size);
        top: @header-size;
        left: 0;
        right: 0;
        z-index: 9998;
        background-color: @mobile-menu-background;
        padding: @mobile-menu-padding-vertical @mobile-menu-padding-horizontal;
        //box-shadow: inset 0 0 @page-shadow-size @page-shadow-color;
        overflow: auto;
        margin-top: 0;

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(100% - @header-size-mobile);
            top: @header-size-mobile;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background: #e9ac50 !important;
            a {
                color: #fff !important;
            }
        }
    }
}


.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
    background-color: #626060 !important;
}

.burger.burger-slip.open .burger-lines{
    background-color: initial !important;
  }


