@import "../../styles/variables.less";

.content {
    width: 100%;
    max-width: calc(@site-max-width + 120px);
    margin: 50px auto;
    padding: 0 60px;
    display: flex;
    @media only screen and (max-width: 800px) {
        padding: 0 20px;
        margin: 20px auto;
        flex-wrap: wrap;
    }

    > div {
        width: 100%;
    }
    
    > ul.actions {
        width: 300px;
        @media only screen and (max-width: 800px) {
            width: 100%;
        }
    }

    .image {
        width: 100%;
        height: auto;
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .text {
        > h1 {
            font-size: 2em;
            font-weight: normal;
            letter-spacing: .01em;
        }
        
        > div {
            font-size: 1.2em;
            > ul {
                margin-left: 40px !important;
            }
        }
    }

    &__text {
        h1 {
            color: #1f1f1f;
            font-weight: 900 !important;
            font-size: clamp(1rem, 10vw, 2rem); 
            margin-bottom: 25px;
            line-height: 1.66em;
        }

        h1::first-letter {
           text-transform: capitalize;
        }

        p, a {
            color: #1f1f1f;
            font-size: @font-paragraph;
        }

        img {
            width: 100%;
            height: auto;
        }

    }

    &__politics-sitana {
        h1 {
            text-align: center;
            font-weight: 400 !important;
        }

        >div {
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
        }
        p {
            color: #000 !important;
            font-size: @font-paragraph;
            line-height: 1.66em;
            font-weight: 400 !important;
        }
    }

    &__image-left {

    }
    &__image-right {

    }
    &__image-top {

    }
    &__image-bottom {

    }

    &__text-service {
        > h1 {
            font-size: 2em;
            font-weight: normal;
            letter-spacing: .01em;
        }
        &__title-border {
            height: 4px;
            width: 8vw;
            margin-top: -1em;
            margin-bottom: 1em;
            background: rgb(255,255,255);
        }
        .ant-btn {
            height: 50px;
            min-width: 190px;
            > span {
                text-transform: uppercase;
                letter-spacing: .05em;
                font-size: 1.2em;
                padding: 0 20px;
            }
        }
    }

    .actions__item {
        background-color: @primary-color;
        text-align: center;
        border-color: @primary-color;
        h4 {
            color: #ffffff;
        }
        a {
            color: @secondary-color;
        }
        &:hover {
            cursor: pointer;
            background-color: @secondary-color;
            color: #000000;
            h4 {
                color: #000000;
            }
            a {
                color: @primary-color;
            }
        }
    }
}
