@import "../../../styles/variables.less";

.listing_item--home-client-list {
    opacity: .8;
    display: block;
    transition: all .3s ease;
    filter: saturate(0);

    &:hover {
        opacity: 1;
        filter: saturate(1);
    }

    img {
        width: 100%;
    }
}

.listing_item--home-service-list {
    padding: calc(20px + 2.5em) 20px 20px;

    >div {
        height: 100%;
        padding: calc(20px + 2em) 20px 20px;
        border-radius: 20px;
        background-color: white;
        box-shadow: @main-card-shadow;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            border-radius: 5px;
            box-shadow: 0 2px 10px 0 rgba(0 0 0 / 30%);
            position: absolute;
            height: 5em;
            top: -2.5em;
            left: 20px;
            background: white;
            border: 5px solid white;
        }

        h3 {
            width: 100%;
            font-size: 1.5em;
            line-height: 1.2em;
            font-family: @headingFont;
            font-weight: 700;
            overflow-wrap: break-word;
        }

        >div {
            font-size: 1.2em;
            line-height: 1.3em;
            color: @primary-color;

            p {
                margin: 0;
            }
        }
    }

    @media only screen and (max-width: @header-mobile-width) {

        >div {
            img {}

            h3 {}

            >div {}
        }

    }
}

.listing__product-list--item {
    min-width: 100%;
    position: relative;
    background-size: cover;

    @media only screen and (min-width: @header-desktop-width) {
        padding: 40px calc((100% - 1140px)/2) 50px !important;
    }

    @media only screen and (max-width: @header-mobile-width) {
        padding: 30px 20px 40px !important;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background: @header-border-bottom;
    }

    &:nth-child(even) {
        flex-direction: row-reverse;

        .ant-col {
            &:nth-child(1)>img {
                padding-left: 40px;
            }
        }
    }

    &:nth-child(odd) {
        .ant-col {
            &:nth-child(1)>img {
                padding-right: 40px;
            }
        }
    }

    .ant-col {
        &:nth-child(1)>img {
            width: 100%;
            max-height: 100%;
            margin: 0 auto;
            display: block;

            @media only screen and (max-width: @header-mobile-width) {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

        }

        &:nth-child(2) {
            img {
                height: 3em;
                margin-bottom: 15px;
            }

            color: @primary-color;
            background-color: rgb(255, 0, 0);
            border-radius: 5px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .1);
            padding: 30px 30px calc(30px - 1em);
        }
    }
}

.listing__about-portfolio-list--item {
    padding: 60px;
    display: flex;

    &:nth-child(odd) {
        padding-left: 0;
    }

    &:nth-child(even) {
        padding-right: 0;
    }

    >div {
        text-align: center;
        padding: 20px 20px 0 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;


        .img {
            border-radius: 20px;
            margin: -20px -20px 20px;
            height: 250px;
            width: calc(100% + 40px);
            background-size: cover;
            background-position: center;
        }

        h2 {
            text-align: center;
            font-size: 2em;
            line-height: 1.1em;
            font-weight: 500;
            color: @banner-heading-color;
            margin-top: 15px !important;
        }

        div {
            font-size: @font-paragraph;
            line-height: 1.2em;
            text-align: left;
            color: @banner-heading-color;
        }

        a {
            position: absolute;
            bottom: 0;
            display: inline-block;
            border-radius: 50px;
            padding: 10px 50px;
            width: auto;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
            background: @actions-item-main-gradient;
            border: 0;
            font-family: @headingFont;
            color: #fff;
            font-weight: 500;
            font-size: 1.1em;
            transition: all .1s ease-in;

            &:hover {
                transform: scale(1.01);
                box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.15);
            }
        }
    }

    @media only screen and (max-width: @header-mobile-width) {

        padding: 20px 0 !important;

        >div {
            h2 {
                font-size: 2.2em;
            }

            .img {
                height: 150px;
            }
        }
    }

}

.listing__about-solution-list-veks, .listing__about-solution-list--item--netuno, .listing__about-solution-list-formation, .listing__about-solution-list-crm, .listing__about-solution-list-ask2me {
    .know-more {
        display: flex;
        justify-content: center;
        position: relative;
        top: 100px;
        margin-bottom: 100px !important;
    }

    a {
        position: absolute;
        bottom: 0;
        display: inline-block;
        border-radius: 50px;
        padding: 10px 50px;
        width: auto;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
        background: @actions-item-main-gradient;
        border: 0;
        font-family: @headingFont;
        color: #fff;
        font-weight: 500;
        font-size: 1.1em;
        transition: all .1s ease-in;

        &:hover {
            transform: scale(1.01);
            box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.15);
        }
    }
    img {
        border: 1px solid #000
    }
}

.listing__about-solution-list--item,
.listing__about-solution-list--item--netuno {

    a {
        // display: flex;
        // padding: 20px;
        // border-radius: 30px;
        // align-items: center;
        // // background-color: @page-background;
        // margin-bottom: 30px;

        &:hover {
            .logo {
                transform: scale(1.02);
                box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
            }
        }

        .logo {
            width: 20%;
            flex: none;
            margin-right: 50px;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            box-shadow: 0 5px 5px rgba(0, 0, 0, .15);
            transition: all .15s ease-in-out;

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }

        div {
            text-align: left !important;
            font-size: 1.1em;
            line-height: 1.2em;
            text-align: center;
            color: rgb(0, 0, 0);

            // a {
            //     display: inline-block;
            //     border-radius: 50px;
            //     padding: 10px 50px;
            //     width: auto;
            //     box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
            //     background: @actions-item-main-gradient;
            //     border: 0;
            //     font-family: @headingFont;
            //     color: #fff;
            //     font-weight: 500;
            //     font-size: 1.1em;
            //     transition: all .1s ease-in;

            //     &:hover {
            //         transform: scale(1.01);
            //         box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.15);
            //     }
            // }
        }
    }

    @media only screen and (max-width: @header-mobile-width) {

        a {
            flex-direction: column;
            //align-items: flex-start;

            .logo {
                width: 60%;
                margin-bottom: 30px;
                margin-right: 0;
            }
        }

        img {
            padding: 0px !important;
            width: 100% !important;
        }

        h1,
        h3 {
            padding: 0px !important;
        }
    }
}

.listing__about-solution-list--item--netuno {

    .ant-row-middle {
        @media only screen and (max-width: @header-mobile-width) {
            flex-direction: column-reverse !important;
        }
    }
}

.img-about-solution {
    width: 100%
}