@import './fonts.less';


@headingFont: 'Poppins';

@highlight-color: #e9ac50;
@highlight-text-color: #CC8519;
@highlight-color-darker: #C3872C;
@primary-color: #e9ac50;
@secondary-color: #d1d1d1;

@highlight-color-secondary: #4986C4;

@site-background: #FCFCFD;
@site-max-width: 1200px;

@page-shadow-size: 25px;
@page-shadow-color: lighten(@primary-color, 50%);
@page-background: #FCFCFD;

@header-background: transparent;
@header-border-bottom: lighten(@primary-color, 40%);
@header-size: 64px;
@header-size-mobile: 64px;
@header-mobile-width: 767px;
@header-laptop-width: 1024px;
@header-desktop-width: 1200px;

@header-background: #ffffff;
@header-menu-color: #f88668;
@header-menu-color-hover: #000000;
@header-size: 64px;
@header-line-height-fix: 0;
@header-line-height: 64px;
@header-size-mobile: 64px;
@header-mobile-width: 768px;
@header-latop-width: 991px;
@header-desktop-width: 1200px;

@font-paragraph: 17px;

@mobile-menu-padding-vertical: 25px;
@mobile-menu-padding-horizontal: 25px;
@mobile-menu-background: #ffffff;

@logo-width: 360px;

@footer-background: #f0f2f5;
@footer-size: 380px;

@actions-item-border-color: #ffffff;
@actions-item-border-width: 2px;
@actions-item-border-radius: 50px;
@actions-item-background: rgba(0, 0, 0, .25);
@actions-item-text-color: #ffffff;
@actions-item-text-color-hover: #000000;
@actions-item-link-color: #ffffff;
@actions-item-link-color-hover: #00A0C6;
@actions-item-background-hover: #ffffff;
@actions-item-main-gradient: linear-gradient(90deg, @highlight-color, @highlight-color-darker);
@actions-item-main-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
@actions-item-main-shadow-hover: 0px 8px 13px 0px rgba(0, 0, 0, 0.2);

@main-card-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
@main-card-ball-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);

@listing-service-list-service-bg: rgba(0, 0, 0, 0.35);

@banner-secondary-darken-bg-color: rgba(0, 0, 0, 0.35);
@banner-secondary-text-shadow: rgba(0, 0, 0, 0.25);

@banner-heading-color: #292d34;
@banner-heading-light-color: #ffffff;
@banner-sub-color: #777777;

@footer-color: #464444;

@banner-sub-banner-gradient: linear-gradient(90deg, rgba(9, 164, 200, 0.2) 0%, #09a4c8 100%);
@banner-sub-banner-bg: #ffffff;
@banner-sub-banner-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
@banner-sub-banner-color: rgba(0, 0, 0, 0.8);


@keyframes homeCustomBannerBlob1 {
    0% {
        transform: rotate(-3deg);
    }

    50% {
        transform: rotate(10deg) scale(1.01);
    }

    100% {
        transform: rotate(-3deg);
    }
}

@keyframes homeCustomBannerBlob2 {
    0% {
        transform: rotate(-6deg);
    }

    50% {
        transform: rotate(15deg) scale(.9);
    }

    100% {
        transform: rotate(-6deg);
    }
}

@keyframes homeCustomBannerBlob3 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-5deg) translateX(-30px);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes homeContactFormBlob1 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(10deg) scale(.9);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes homeContactFormBlob2 {
    0% {
        transform: rotate(-6deg);
    }

    50% {
        transform: rotate(15deg) scale(1.01);
    }

    100% {
        transform: rotate(-6deg);
    }
}

@keyframes homeContactFormBlob3 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
