@import '~@animated-burgers/burger-slip/dist/styles.css';
@import '~antd/dist/reset.css';
@import '~sal.js/dist/sal.css';

@import "./variables.less";

* {
    font-family: 'Roboto', sans-serif;
}

html,
body {
    background-color: @site-background;
    line-height: 1.5715;
}


/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .25);
    border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: @banner-sub-color;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .15);
}

.ant-layout-content main {
    padding-bottom: 6vw;

    &::before {
        content: "";
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-size: contain;
        background: linear-gradient(90deg, rgba(250, 251, 252, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    z-index: -2;
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    background-image: radial-gradient(#ececed 12%, transparent 12%),
    radial-gradient(#ececed 12%, transparent 12%);
    background-color: #fafbfc;
    background-position: 0 0,
    15px 15px;
    background-size: 30px 30px;
    background-repeat: repeat;
}

.ant-layout {
    margin-top: @header-size;
    background-color: @page-background;

    @media only screen and (max-width: @header-mobile-width) {
        margin-top: @header-size-mobile;
    }
}



.ant-layout-content {
    min-height: calc(100vh);
    background-color: @page-background;
    z-index: 2;
}

p {
    color: #000 !important;
}
