@import "../../styles/variables.less";

.listing {
    margin: auto;

    &__title-border {
        height: 4px;
        width: 8vw;
        margin-top: -1em;
        margin-bottom: 1em;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(9, 164, 200, 0.2) 0%, rgba(9, 164, 200, 1) 100%);
    }

    >div>h1 {
        font-size: 2em;
        font-weight: normal;
        letter-spacing: .01em;
    }

    >div>ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__home-client-list {
        text-align: center;
        position: relative;
        margin: @header-size 0;
        padding: 20px 50px;
        background-color: @page-background;

        @media only screen and (min-width: @header-desktop-width) {
            padding: 20px calc((100% - 1140px)/2) !important;
        }

        @media only screen and (max-width: @header-mobile-width) {
            padding: 20px 10% !important;
            margin: 0;
        }

        h2 {
            padding: 10px 0;
            text-transform: uppercase;
            color: @banner-sub-color;
            font-size: 1.4em;
            line-height: 1.3em;
            margin: 0 auto;
            font-weight: 700;
            margin-bottom: 20px;
            display: inline-block;
            background-color: @page-background;
        }

    }

    &__home-service-list {
        position: relative;
        margin: @header-size 0;
        padding: 20px 50px;

        @media only screen and (min-width: @header-desktop-width) {
            padding: 20px calc((100% - 1140px)/2) !important;
        }

        @media only screen and (max-width: @header-mobile-width) {
            padding: 20px 10% !important;
        }

        h2 {
            font-size: 3em;
            line-height: 1.2em;
            font-family: @headingFont;
            font-weight: 700;
            color: @banner-heading-color;

            span {
                color: @highlight-text-color;
            }
        }

        p {
            font-size: @font-paragraph  !important;
        }

        >div:not(.ant-row) {
            font-size: 1.6em;
            line-height: 1.3em;
        }

        .ant-row {
            margin: 0 -20px;
        }


        @media only screen and (max-width: @header-mobile-width) {

            h2 {
                font-size: 2.2em;
            }

            >div:not(.ant-row) {
                font-size: 1.2em;
            }

        }
    }

    &__about-portfolio-list {
        position: relative;
        margin: @header-size 0;
        padding: 20px 50px;
        margin-top: 0 !important;

        h2 {
            margin-top: 64px;
            text-align: center;
            font-size: 3em;
            line-height: 1.2em;
            font-family: @headingFont;
            font-weight: 700;
            color: @banner-heading-color;

            span {
                color: @banner-sub-color;
            }
        }

        @media only screen and (min-width: @header-desktop-width) {
            padding: 20px calc((100% - 1140px)/2) !important;
        }

        @media only screen and (max-width: @header-mobile-width) {
            padding: 20px 10% !important;

            h2 {
                font-size: 2.2em;
            }
        }
    }

    &__about-solution-list {
        position: relative;
        margin: @header-size 0;
        padding: 20px 50px;
        margin-top: 0 !important;

        h2 {
            margin-top: 64px;
            font-size: 3em;
            line-height: 1.2em;
            font-family: @headingFont;
            font-weight: 700;
            color: @banner-heading-color;
            text-align: center;

            span {
                color: red;
            }
        }

        @media only screen and (min-width: @header-desktop-width) {
            padding: 20px calc((100% - 1140px)/2) !important;
        }

        @media only screen and (max-width: @header-mobile-width) {
            padding: 20px 10% !important;

            h2 {
                font-size: 2.2em;
                text-align: center !important;
            }

            margin: 0px !important;
        }
    }

    &__about-solution-list-veks, &__about-solution-list-netuno, &__about-solution-list-formation,  &__about-solution-list-crm, &__about-solution-list-ask2me{
        position: relative;
        margin: @header-size 0;
        padding: 20px 50px;
        margin-top: 0 !important;

        h2 {
            margin-top: 64px;
            font-size: 3em;
            line-height: 1.2em;
            font-family: @headingFont;
            font-weight: 700;
            color: @banner-heading-color;

            span {
                color: @highlight-text-color;
            }
        }

        @media only screen and (min-width: @header-desktop-width) {
            padding: 20px calc((100% - 1140px)/2) !important;
        }

        @media only screen and (max-width: @header-mobile-width) {
            padding: 20px 10%  80px!important;

            h2 {
                font-size: 2.2em;
                text-align: center !important;
            }

            margin: 0px !important;
        }
    }
}