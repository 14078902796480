.cookies {
    position: absolute;
    height: 100px;
    background-color: #777777;

  
    &--popup {
        position: fixed;
        z-index: 10000;
        width: 100%;
        height: auto;
        bottom: 32px;
        background-color: #fff;
        box-shadow: 0 -1px 10px 0 rgba(172, 171, 171, 0.3);
        padding: 0 calc((100% - 1140px)/2) !important;

        &__content {
            color: rgba(0, 0, 0, 0.85);
            width: min(90%,1120px)!important;
            margin: 0 auto;
            padding: 20px 10px;
            @media only screen and (max-width: 800px) {
                padding: 20px 0;
            }
            display: flex;
            justify-content: space-between;
            p {
                margin-bottom: 0;
                a {
                    color: #e9ac50 !important; 
                }
                a:hover {
                    color: #f5c87a !important;
                }
            }

            @media only screen and (max-width: 768px) {
                display: block;
                p {
                    margin-bottom: 1em;
                }
            }
        }
    }
}
