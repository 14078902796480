@import "../../styles/variables.less";

.ant-layout-footer {
    display: flex;
    background-color: @footer-background;
    z-index: 2;
    width: 100%;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        top: calc(-6vw + 2px);
        left: 0;
        height: 6vw;
        z-index: -1;
    }

    span, a {
        color: #000; 
        &:hover {
            color: #E4922F !important;
        }
    }

    @media only screen and (min-width: @header-desktop-width) {
        padding: 0 calc(50% - 600px) !important;
    }

    @media only screen and (max-width: @header-mobile-width) {
        padding: 40px 10% !important;
        text-align: center;

        .ant-row {
            justify-content: center;
        }
    }

    .logo {
        max-width: 200px;
        max-height: 64px;
        margin-bottom: 10px;

        @media only screen and (max-width: @header-mobile-width) {
            margin: 0 auto 20px;
        }

        img {
            width: 100%;
            padding: 15px 0;
        }
    }

    .info,
    .social,
    .contacts {
        color: @footer-color;
    }

    .social {
        padding: 20px 0;
        .ant-col {
            max-width: 50px;
            a {
                .anticon {
                    font-size: 2em;
                }
    
                &:hover {
                    color: #E4922F !important;
                }
            }
        } 
    }
    .contacts {
        @media only screen and (min-width: 995px) {
            margin-top: 16px;
        }
        
    }

    .info, .social {

        a {
            &:hover {
                color: #E4922F !important;
            }
        }
    }

    .vertical-bar, .copy {
        &:hover {
            color: #000 !important;
        }
    }

    hr {
        margin-bottom: 20px;
        border: 1px solid @page-background;
    }

    &>div:nth-child(2) {
        p {
            margin: 0;
        }
    }
}
