.actions {
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 20px;
    @media only screen and (max-width: 800px) {
        padding: 0 0 0 0;
        display: flex;
        flex-wrap: wrap;
    }
}
