@import "../../styles/variables.less";

.banner {
    &__sub-banner {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        background: @banner-sub-banner-gradient;
        background-color: @banner-sub-banner-bg;
        padding: 15px 0;
        width: 100% !important;
        text-align: center;
        font-weight: bold;
        font-size: 1.3em;
        letter-spacing: .025em;
        box-shadow: @banner-sub-banner-box-shadow;
        color: @banner-sub-banner-color;

        @media only screen and (max-width: @header-mobile-width) {
            font-size: 1.2em;
        }
    }

    &__default-sub-banner {
        padding-bottom: calc(1.8em + 30px) !important;

        .banner__sub-banner {
            display: block;
        }

        >div>div>div {
            p {
                margin-bottom: 0;

                &:nth-last-child(1) {
                    margin-bottom: 1em;
                }
            }
        }

        @media only screen and (max-width: @header-mobile-width) {
            >div>div>div {
                margin: auto;
            }
        }
    }

    &__home {

        &-sitana {
            margin-top: -@header-size;
            position: relative;
            min-height: 100vh !important;
            overflow: hidden;
            background-image: unset !important;
            text-align: left !important;

            @media only screen and (min-width: @header-desktop-width) {
                padding: @header-size calc((100% - 1140px)/2) !important;
            }

            img {
                width: 100%;
                z-index: 3;
                position: relative;
            }

            h1 {
                font-size: 3em;
                line-height: 1.2em;
                font-family: @headingFont;
                font-weight: 500;
                text-transform: uppercase;
                color: @banner-heading-color;

                span {
                    font-weight: 700;
                    display: block;
                    color: @highlight-text-color;
                }
            }

            p {
                font-size: 1.6em;
                line-height: 1.3em;
                color: @banner-sub-color;
            }

            .actions {
                z-index: 5;

                &__item {
                    h4 {
                        display: none;
                    }

                    a {
                        font-family: @headingFont;
                        color: #fff;
                        font-weight: 500;
                        font-size: 1.2em !important;
                    }

                    padding: 10px 50px;
                    width: auto;
                    box-shadow: @actions-item-main-shadow;
                    background: @actions-item-main-gradient;
                    border: 0;

                    &:hover {
                        transform: scale(1.01);
                        box-shadow: @actions-item-main-shadow-hover;
                    }
                }
            }

            &--blobs__desktop,
            &--blobs__mobile {
                position: absolute;

                >img {
                    position: absolute;
                    height: calc(100% - 20px);
                    top: 10px;
                    left: 0;
                    width: auto;

                    &:nth-child(1) {
                        z-index: 0;
                        animation: homeCustomBannerBlob1 16s infinite ease-in-out;
                    }

                    &:nth-child(2) {
                        z-index: 1;
                        animation: homeCustomBannerBlob2 16s infinite ease-in-out;
                    }

                    &:nth-child(3) {
                        z-index: 2;
                        animation: homeCustomBannerBlob3 16s infinite ease-in-out;
                    }
                }
            }

            &--blobs__desktop {
                height: calc(100% - @header-size);
                width: 50% !important;
                z-index: 0;
                right: 0;
                top: @header-size - 20px;
            }

            &--blobs__mobile {
                display: none;
                height: calc(100% + 10vw) !important;
                width: calc(100% + 20vw) !important;
                bottom: -20px;
                right: -20vw;
            }

            @media only screen and (max-width: @header-mobile-width) {
                min-height: calc(100vh - 56px) !important;
                padding: @header-size 10% 20px !important;

                h1 {
                    font-size: 2.2em;
                }

                p {
                    font-size: 1.2em;
                }

                .actions {
                    margin-bottom: 20px;
                }

                &--blobs__desktop {
                    display: none;
                }

                &--blobs__mobile {
                    display: block;
                }
            }
        }


        &-about {
            width: auto !important;
            background-image: unset !important;
            margin: calc(@header-size + 2.5em) 0 @header-size  !important;

            @media only screen and (min-width: @header-desktop-width) {
                margin: calc(@header-size + 2.5em) calc((100% - 1140px)/2) @header-size  !important;
            }

            .ant-col {
                background-color: @page-background;
                padding: 2.5em 30px 30px !important;
                box-shadow: @main-card-shadow;
                border-radius: 30px;
                position: relative;
                text-align: center;

                img {
                    top: -2.5em;
                    left: calc(50% - 2.5em);
                    border-radius: 50%;
                    position: absolute;
                    height: 5em;
                    box-shadow: @main-card-ball-shadow;
                }

                h3 {
                    font-size: 2em;
                    padding-top: 20px;
                    text-transform: uppercase;
                    line-height: 1.2em;
                    font-family: @headingFont;
                    font-weight: 700;
                    color: @banner-heading-color;

                    span {
                        color: @highlight-text-color;
                        font-weight: 500;
                    }
                }

                >div {
                    p {
                        font-size: 1.2em;
                        line-height: 1.3em;
                        color: @primary-color;
                    }
                }
            }

            .actions {

                &__item {
                    padding: 10px 50px;
                    width: auto;
                    background: transparent;
                    border: 2px solid @primary-color;
                    margin: 0;

                    h4 {
                        display: none;
                    }

                    a {
                        font-family: @headingFont;
                        color: @primary-color;
                        font-weight: 700;
                        font-size: 1.2em;
                    }

                    &:hover {
                        transform: scale(1.01);
                        box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.05);
                    }
                }
            }

            @media only screen and (max-width: @header-mobile-width) {
                padding: @header-size 10% 20px !important;

                h3 img {
                    margin-bottom: 20px;
                }

                .actions {
                    margin-bottom: 20px;
                }
            }
        }

        &-shop-hook {
            position: relative;
            background-image: unset !important;
            text-align: left !important;

            @media only screen and (min-width: @header-desktop-width) {
                padding: @header-size calc((100% - 1140px)/2) !important;
            }

            .ant-row {
                position: relative;
                padding: 20px;
                border-radius: 30px;
                background-color: @page-background;
                box-shadow: @main-card-shadow;
                min-height: 500px;
                background-size: cover;
                background-position: center;
                overflow: hidden;

                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    display: block;
                    position: absolute;
                    background: linear-gradient(90deg, white 35%, rgba(255, 255, 255, 0) 100%);
                }
            }

            h1 {
                padding: 0 20px;
                font-size: 3em;
                line-height: 1.2em;
                font-weight: 700;
                color: @banner-heading-color;

                span {
                    color: @highlight-text-color;
                }
            }

            p {
                padding: 0 20px;
                font-size: 1.6em;
                line-height: 1.3em;
                color: @primary-color;
            }

            .actions {
                padding-left: 20px !important;

                &__item {
                    h4 {
                        display: none;
                    }

                    a {
                        font-family: @headingFont;
                        color: #fff;
                        font-weight: 500;
                        font-size: 1.2em !important;
                        text-shadow: unset;
                    }

                    padding: 10px 50px;
                    width: auto;
                    box-shadow: @actions-item-main-shadow;
                    background: @actions-item-main-gradient;
                    border: 0;

                    &:hover {
                        transform: scale(1.01);
                        box-shadow: @actions-item-main-shadow-hover;
                    }
                }
            }

            @media only screen and (max-width: @header-mobile-width) {
                padding: @header-size 10% 20px !important;

                .ant-row {
                    align-items: unset !important;
                    background-size: auto 50%;
                    background-position: center bottom;
                    background-repeat: no-repeat;

                    &:before {
                        background: linear-gradient(180deg, white 35%, rgba(255, 255, 255, 0) 100%);
                    }
                }

                .actions {
                    width: 100%;
                    padding: 0 5px !important;
                    margin-top: 200px !important;

                    &__item p {
                        padding: 0;
                    }
                }

                h1 {
                    padding: 5px;
                    font-size: 2em;
                }

                p {
                    padding: 0 5px;
                    font-size: 1.2em;
                }

                img {
                    margin-top: 20px;
                }

                &--blobs__desktop {
                    display: none;
                }

                &--blobs__mobile {
                    display: block;
                }

            }
        }

        &-netuno {
            height: auto !important;
            background-image: unset !important;
            background-color: #4986C4;//@highlight-color;
            position: relative;
            margin: calc(3.333vw*2 + @header-size) 0;
            text-align: left !important;

            @media only screen and (min-width: @header-desktop-width) {
                padding: 40px calc((100% - 1140px)/2) !important;
            }

            img {
                max-width: 100%;
                padding: 0 22px;

                &.top,
                &.bottom {
                    position: absolute;
                    padding: 0;
                    left: 0;
                    height: 5.555vw;
                    width: 100%;
                }

                &.top {
                    top: calc(-3.333vw + 1px);
                }

                &.bottom {
                    bottom: calc(-3.333vw + 1px);
                }
            }

            h2 {
                padding: 20px 20px 0;
                font-size: 2em;
                line-height: 1.2em;
                font-family: @headingFont;
                font-weight: 500;
                color: @banner-heading-light-color;
                text-shadow: 0 0 4px rgba(0, 0, 0, .15);

                span {
                    font-weight: 700;
                }
            }

            p {
                padding: 0 20px;
                font-size: 1.2em;
                line-height: 1.3em;
                color: #fff !important;
            }

            .actions {
                width: 100%;
                text-align: left !important;
                padding-left: 20px !important;

                @media only screen and (min-width: 992px) {
                    position: absolute !important;
                    bottom: 0;
                    left: 0;
                }

                li {
                    display: inline-block;

                    &:nth-child(1) {
                        .actions__item {
                            box-shadow: @actions-item-main-shadow;
                            background: linear-gradient(90deg, #FAFBFC, #EFF0F0);

                            a {
                                color: #4986C4;//@highlight-color;
                            }

                            &:hover {
                                transform: scale(1.01);
                                box-shadow: @actions-item-main-shadow-hover;
                            }

                        }
                    }

                    &:nth-child(2) {
                        .actions__item {
                            background-color: transparent;

                            a {
                                color: #fff;
                            }

                            &:hover {
                                transform: scale(1.01);
                                text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
                            }
                        }
                    }
                }

                &__item {
                    padding: 10px 50px;
                    width: auto;
                    border: 0;
                    font-family: @headingFont;

                    h4,
                    p {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: @header-mobile-width) {
                padding: 40px 20px !important;

                .actions {
                    margin-top: 20px;
                    padding-left: 10px !important;

                    li {
                        .actions__item {
                            padding: 10px 20px;

                            a {
                                font-size: 1em;
                            }
                        }
                    }
                }

                h2 {
                    padding: 10px;
                    font-size: 2em;
                }

                p {
                    padding: 0 10px;
                    font-size: 1.1em;
                }
            }
        }
    }

    &__about {
        &-about-us {
            margin-top: -@header-size;
            position: relative;
            background-image: unset !important;
            text-align: left !important;
            padding: calc(@header-size + 10vh) 50px 20px !important;

            @media only screen and (min-width: @header-desktop-width) {
                padding: calc(@header-size + 10vh) calc((100% - 1140px)/2) 10vh !important;
            }

            img {
                padding-left: 40px;
                max-width: 100%;
            }

            h1 {
                font-size: 3em;
                line-height: 1.2em;
                font-family: @headingFont;
                font-weight: 700;
                color: @banner-heading-color;

                span {
                    color: @highlight-text-color;
                }
            }

            p {
                font-size: 1.3em;
                line-height: 1.3em;
                color: @banner-sub-color;
            }

            @media only screen and (max-width: @header-mobile-width) {
                padding: calc(@header-size + 10vh) 10% 20px !important;

                h1 {
                    font-size: 2.5em;
                }

                p {
                    font-size: 1.2em;
                }

                img {
                    padding: 0;
                }
            }

        }
    }

    &__banner-client {
        position: relative;
        background-size: cover;
        height: 600px;
        width: 100%;
        overflow: hidden;
        background-position: center center !important;
        padding: 0 !important;

        @media only screen and (max-width: @header-mobile-width) {
            padding: 40px calc((100% - 1140px)/2) !important;
            height: 250px !important;
            height: calc(30vh - @header-size-mobile);
            overflow: hidden !important;

            img {
                width: 100%;
            }
        }
        
    }

    &__default,
    &__default-sub-banner {
        min-height: 600px;
        height: calc(100vh - @header-size);

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(100vh - @header-size-mobile);
        }

        .banner__darken-bg {
            display: none;
        }
    }

    &__secondary {
        position: relative;
        height: calc(40vh - @header-size);
        text-align: left;

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(30vh - @header-size-mobile);
        }

        div:nth-child(1) {
            z-index: 2;

            h2 {
                text-shadow: 0 0 10px @banner-secondary-text-shadow;
            }
        }

        .banner__darken-bg {
            width: 100%;
            height: 100%;
            background-color: @banner-secondary-darken-bg-color;
            position: absolute;
            z-index: 1;
        }

        @media only screen and (max-width: @header-mobile-width) {
            height: calc(33vh - @header-size-mobile) !important;
        }
    }

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 0 50px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;

        @media only screen and (max-width: @header-mobile-width) {
            //height: calc(100vh - @header-size-mobile);
            min-height: unset;
            text-align: center;
            padding: 0;
        }

        >div {
            width: 100%;

            >div {
                >div {
                    width: 100%;
                    height: auto;

                    >h1 {
                        margin-bottom: 0;
                        font-weight: 200;
                        font-size: 3.5em;
                        color: #fff;
                        letter-spacing: .01em;

                        @media only screen and (max-width: @header-mobile-width) {
                            font-size: 2em;
                            line-height: 1.2em;
                            margin-bottom: 10px;
                        }
                    }

                    >div {
                        >p {
                            font-size: 1.4em;
                            color: #fff;

                            @media only screen and (max-width: @header-mobile-width) {
                                font-size: 1.5em;
                                line-height: 1.5em;
                            }
                        }
                    }
                }
            }

            .actions {
                position: relative;
                display: inline-block;
                padding-left: 0;
                text-align: center;

                .actions__item {
                    h4 {
                        font-size: 1.0em;
                        margin-bottom: 0.1em;
                    }

                    p {
                        font-size: 1.1em;
                        margin-bottom: 0.1em;
                    }

                    a {
                        font-size: 1em
                    }
                }
            }
        }
    }
}
